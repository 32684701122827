import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  //   .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: "ua",
    returnObjects: true,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          headerNav1: "Home",
          headerNav2: "About us",
          headerNav3: "Our benefits",
          headerNav4: "Our services",
          headerNav5: "Contacts",

          mainDescription: "We deliver all over the world",
          mainButton: "Send a request",

          aboutSmall: "About us",
          aboutBig1:
            "We are a Ukrainian company that has been operating since 2017 supplying grain and oil crops from Ukrainian farmers to the domestic market of Ukraine and exporting to Europe, Asia, Africa and America. We provide the service for cleaning and packaging of goods, which is carried out at our production facility in Odesa, Ukraine.",
          aboutBig2: "Terra Group Ukraine is your reliable partner in the supply of grains, oilseeds and pulses.",
          aboutTitle: "The highest quality",
          aboutDescription: "We guarantee the high quality of our products and services.",

          productsSmall: "Our products",
          productsBig: "We supply the highest quality products to all our partners!",

          advantagesSmall: "Our Benefits",
          advantagesBig:
            "More than 7 years of cooperation with different countries gave us the opportunity to make ourselves known!",
          advantagesDescTitle_1: "High quality products:",
          advantagesDesc_1:
            "The company ensures that its grain meets international quality standards, which guarantees its safety and efficiency in use.",
          advantagesDescTitle_2: "Efficient logistics:",
          advantagesDesc_2:
            "Thanks to a well-established logistics system, the company ensures timely delivery of grain to customers, minimizing delays and costs.",
          advantagesDescTitle_3: "Wide supply network:",
          advantagesDesc_3:
            "The company has well-established supply channels to international markets, including Europe, Asia and Africa, which allows it to meet the needs of different customers.",
          advantagesDescTitle_4: "Competitive prices:",
          advantagesDesc_4:
            "Due to the scale of its operations and efficient cost management, the company is able to offer competitive grain prices.",
          advantagesDescTitle_5: "Reliability and reputation:",
          advantagesDesc_5:
            "The company has a positive reputation in the market for its reliable service and individual approach to the needs of each of our partners.",
          advantagesDescTitle_6: "Flexibility and adaptability:",
          advantagesDesc_6: "The company can quickly adapt to changing market conditions and customer requirements.",
          advantagesDescTitle_7: "Modern technologies:",
          advantagesDesc_7:
            "Using advanced technologies for quality control and supply management, the company ensures high efficiency and accuracy in order fulfillment.",

          servicesSmall: "Our services",
          servicesBig: "With us, you get a reliable partner who will help you maximize the potential of your business",
          service1: "Purchase",
          service1Text:
            "We cooperate with reliable farmers throughout Ukraine, who grow their goods in accordance with all international standards.",
          service2: "Processing",
          service2TextFirst:
            "Сleaning, packaging and loading of goods is carried according to the client's needs at our facility in Odessa.",
          service2TextSecond:
            "Possible packing in 10/20/25/50 kgs PP bags or big bags. We also pack in bags with a print according to an individual layout.",
          service3: "Delivery",
          service3Text: "Cargo can be delivered in 20ft or 40ft containers or by trucks.",

          footerInput1: "Name",
          footerInput2: "Phone",
          footerInput3: "Chose products",
          footerInput4: "Additional information",
          footerInputButton: "Send",
          footerContactTitle: "Contact us",
          footerContactLocation: "Odesa, Arcadia plateau",

          rights: "© 2024 Terra Group | All rights reserved",
          modalTitle: "Send a message",
        },
      },
      ua: {
        translation: {
          headerNav1: "Головна",
          headerNav2: "Про нас",
          headerNav3: "Наші переваги",
          headerNav4: "Наші послуги",
          headerNav5: "Контакти",

          mainDescription: "Доставляємо по всьому світу",
          mainButton: "Відправити заявку",

          aboutSmall: "Про нас",
          aboutBig1:
            "Ми українська компанія, яка працює з 2017 року, поставляємо зернові та олійні культури від українських фермерів на внутрішній ринок України та експортуємо на ринки Європи, Азії, Африки та Америки.",
          aboutBig2: "Компанія Терра Груп Україна є вашим надійним партнером у поставках зернових та олійних культур.",
          aboutTitle: "Найвища якість",
          aboutDescription: "Ми гарантуємо високу якість нашої продукції та послуг.",

          productsSmall: "Наша продукція",
          productsBig: "Ми постачаємо продукцію найвищої якості до всіх наших партнерів!",

          advantagesSmall: "Наші переваги",
          advantagesBig: "Більше 7-ми років співпраці з різними країнами дали нам можливість заявити про нас!",
          advantagesDescTitle_1: "Висока якість продукції:",
          advantagesDesc_1:
            "Компанія забезпечує відповідність зерна згідно міжнародним стандартам якості, що гарантує його безпеку та ефективність у використанні.",
          advantagesDescTitle_2: "Ефективна логістика:",
          advantagesDesc_2:
            "Завдяки добре налагодженій логістичній системі компанія забезпечує своєчасну доставку зерна до клієнтів, мінімізуючи затримки і витрати.",
          advantagesDescTitle_3: "Широка мережа постачання:",
          advantagesDesc_3:
            "Компанія має налагоджені канали постачання на міжнародні ринки, зокрема в Європу, Азію та Африку, що дозволяє задовольняти потреби різних клієнтів.",
          advantagesDescTitle_4: "Конкурентоспроможні ціни:",
          advantagesDesc_4:
            "Завдяки масштабам діяльності і ефективному управлінню витратами компанія може пропонувати конкурентоспроможні ціни на зерно.",
          advantagesDescTitle_5: "Надійність і репутація:",
          advantagesDesc_5:
            "Компанія має позитивну репутацію на ринку завдяки своєму надійному сервісу та індивідуальному підходу до потреб кожного з наших партнерів.",
          advantagesDescTitle_6: "Гнучкість і адаптивність:",
          advantagesDesc_6: "Компанія може швидко адаптуватися до змінюваних умов ринку та вимог клієнтів.",
          advantagesDescTitle_7: "Сучасні технології:",
          advantagesDesc_7:
            "Використання передових технологій для контролю якості та управління постачанням дозволяє компанії забезпечувати високу ефективність і точність у виконанні замовлень.",

          servicesSmall: "Наші послуги",
          servicesBig:
            "З нами Ви отримуєте надійного партнера, який допоможе Вам максимально реалізувати потенціал вашого бізнесу",
          service1: "Закуповуємо",
          service1Text:
            "Ми співпрацюємо з надійними фермерами по всій Україні, які вірощують товар згідно всім нормам.",
          service2: "Комплектуємо",
          service2TextFirst:
            "Очищуємо, фасуємо та вантажимо товар на нашому виробництві в Одесі, згідно до потреб клієнта.",
          service2TextSecond:
            "Можливо фасування у 10/20/25/50 кілограмові поліпропіленові мішки або біг беги. Також фасуємо у мішки з принтом за індивідуальним макетом.",
          service3: "Доставляємо",
          service3Text: "Доставляємо у 20- або 40-футових контейнерах або авто.",

          footerInput1: "Ім'я",
          footerInput2: "Телефон",
          footerInput3: "Оберіть товари",
          footerInput4: "Додаткова інформація",
          footerInputButton: "Відправити",
          footerContactTitle: "Зв’яжіться з нами",
          footerContactLocation: "м.Одеса, Аркадійське плато",

          rights: "© 2024 Terra Group | Всі права захищені",
          modalTitle: "Відправити повідомлення",
        },
      },
    },
  });

export default i18n;
